import "./common.css";
import React, { useEffect, useState ,useRef,useContext,  useImperativeHandle,  forwardRef} from "react";
import { Link ,useNavigate,useLocation} from "react-router-dom";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { post_Request, post_RequestSearch } from "../Controller/Login";
import { postRequest, postRequest2 } from "../Controller/API";
import { IoSearchOutline } from "react-icons/io5";
import { CiHeart } from "react-icons/ci";
import { FaBars } from "react-icons/fa6";
import { IoGiftOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { CiUser } from "react-icons/ci";
import { Store } from "../Redux/store";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signInWithRedirect,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import {
  faHeart,
  faHeart as faHeartRegular,
} from "@fortawesome/free-solid-svg-icons";
import {
  isValidMobile,
  validateEmail,
  validateOTP,
  validateRegistration,
} from "./Validation/loginValidation";
import { AppRegistration } from "@mui/icons-material";
import { getSessionForLoginUser, setSessionForLoginUser } from "../utils/helper";
import { IMAGE_BASE_URL } from "../Config/constant";
import ThankYoupopup from "./PopupModals/thankYoupopup";
import { addToCart, addWishlist, removeWishlist } from "../Controller/commonController";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city';
import axios from "axios";

const Header = forwardRef((props, ref) => {
  const { cart ,getCart,updateCartItemQuantity,subtotal,wishlistData,categories,product,wishlist} = useContext(Store);
  const navigate=useNavigate();
  const inputRefs = useRef([]);
  const User = getSessionForLoginUser('User');
  const [isSticky, setIsSticky] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [productDetailsSize,setProductDetailsSize]=useState(null)
  const[colorIndex,setColorIndex]=useState('')
  const [quantity, setQuantity] = useState(1);
  const [menuOpen, setMenuOpen] = useState(false);
  const[content,setContent]=useState('')
  const[showPopup,setShowPopup]=useState(false)
  const[productSieColor,setProductSieColor]=useState(null)
  const [selectedSize,setSelectedSize]=useState(null)
  const [tempData,setTempData]=useState('')
  const[showSizepopup,setShowSizepopup]=useState(false)
  const images_url = "https://sportking.s3.eu-north-1.amazonaws.com/image/";
// add to poduct from header
const handleColorSelect = (productId, colorId,color,colorIndex) => {
  setColorIndex(prevState => ({
    ...prevState,
    productId: productId,
    index: colorIndex
  }));
  
const temp={
  productId:productId,
  colorId:colorId
}
setSelectedColor(temp)
setProductDetailsSize(color)
};

const handleCart = async (productDetails,data) => {

  setProductSieColor(data)
  if (selectedSize === null) {
    const temp ={ productDetails,data}
    setTempData(temp)
    setShowSizepopup(true)
  } else {
    try {
      const response=await addToCart(productDetails||tempData.productDetails, selectedSize, productSieColor)
      if(response){
        setContent({ h1: "Added to Cart", p: null });
        // setShowPopup(true);
        toast.success('Added to Cart');
        getCart()
        setSelectedSize(null);setError('')
      }

    } catch (error) {
      console.error("Error adding to cart:", error);
      // Handle error here, e.g., show error message
    }
  }
};

const handleNavigate=()=>{
  if (selectedSize === null) {
    setError("Please Select Size");
  }else{
    setSelectedSize(null)
    if(tempData?._id){
      handleWishlist(tempData?._id)
    }
    handleCart()
    setShowSizepopup(false)
  }

}

const handleWishlist = async (id) => {
  if (selectedSize === null) {
    setError("Please Select Size");
    return
  }
  const response=await addWishlist(id,productSieColor,selectedSize)
    if(response.status===true){
      wishlist()
      setContent({ h1: "Added to Wishlist", p: null ,h7:"Ok"});
      toast.success('Added to Wishlist');
      // setShowPopup(true);
    }  
};

const handleRemoveWishlist = async item => {
  
  try {
    const data = {
      product_id: item._id
    }
    const response = await removeWishlist(data)
    if (response) {
      setContent({ h1: 'Item removed from wishlist.', p: null ,h7:"Ok"})
      toast('Item removed from wishlist.');
      // setShowPopup(true)
      wishlist()
    }
  } catch (error) {
    console.error(error)
  }
}

const handleHeartClick = (item,data,status) => {
  if(status===true){
    handleRemoveWishlist(item)
    return
  }
      setProductSieColor(data)
      setTempData(item)
      if (selectedSize === null) {
        // setError("Please Select Size");
        setShowSizepopup(true)
        return
      }
      handleWishlist(item._id);
    };
    
  const isProductInWishlist = (productId) => {
    return wishlistData&& wishlistData?.some((item) => item?.product_id?._id === productId) || false;
  };
// end

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleIncrement = async (item) => {
   await updateCartItemQuantity(item,1)
    if (quantity < 20) {
      setQuantity(quantity + 1);
    }
  };

  const handleDecrement = (item) => {
    updateCartItemQuantity(item,-1).then((res)=>{})
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    set_thnaks_confirm(false);
    set_address(false);
    set_otp(false);
    set_loginpopup(false);
    setShowSizepopup(false)
  };
  const handleShow = () => {
    getCart()
    setShow(true);
  };

  // thanks_popup
  const [thnaks_confirm, set_thnaks_confirm] = useState(false);

  const thank_event = () => {
    set_thnaks_confirm(true);
  };
  // loginpopup

  const [loginpopup, set_loginpopup] = useState(false);
  const sign_up = () => {
    setShow(false)
    set_loginpopup(true);
  };
  useImperativeHandle(ref, () => ({// Corrected the method name here
    openModel: sign_up,
  }));
  //otp verfication
  const [otp, set_otp] = useState(false);
  const [otpValue, setOtpValue] = useState(["", "", "", "", "", ""]);
  const[wrongOtp,setWrongOtp]=useState(null)
  const [address, set_address] = useState(false);

  const update_address_event = () => {
    set_address(true);
    setShow(false);
    set_otp(false);
  };
const handleChange = (index, newValue) => {
  setWrongOtp('')
  // Ensure newValue is a single digit
  if (/^\d$/.test(newValue)) {
    const newOtpValue = [...otpValue];
    newOtpValue[index] = newValue;
    setOtpValue(newOtpValue);

    // Move to the next input field automatically if a digit is entered
    if (newValue !== "" && index < otpValue.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  } else if (newValue === "") {
    // Allow clearing the input field
    const newOtpValue = [...otpValue];
    newOtpValue[index] = ""; // Clear the value if backspace/delete is pressed
    setOtpValue(newOtpValue);

    // Move to the previous input field if the current field is empty and Backspace is pressed
    if (index > 0) {
      inputRefs.current[index - 1].focus();
    }
  }
};

  const handleLoginOtp = async (e) => {
    e.preventDefault();
if(wrongOtp ===null){
setWrongOtp('Otp is required')
return
}
    if (validateOTP(otpValue)) {
      const options = {
        url: "/users/verify-login-otp",
        postData: {
          email_mob: email,
          otp: otpValue.join(""),
        },
      };

      try {
        const response = await post_Request(options);
        const UserData=response?.result
        if (response && response.status === true) {
          if(UserData?.token){
            setSessionForLoginUser('User',response.result)
            sessionStorage.setItem('TOKEN',response.result.token)
          if(UserData?.firstName&& UserData?.email && UserData?.mobile && UserData?.gender){
            set_otp(false);
            navigate(1)
          }else{
            set_address(true);
            setShow(false);
            set_otp(false);
          }
        }

        } else {
          setWrongOtp("Invalid OTP");
          // Handle invalid OTP case
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle error if any
      }
    } else {
      setWrongOtp("Invalid OTP");
      // Handle invalid OTP case
    }
  };

  // emial verification
  const handleLogin = async () => {
    if (!email.trim()) {
      setIsEmailTouched(true);
      setIsValidEmail(false);
      return;
    }
    if (isValidEmail) {
      try {
        const options = {
          url: "/users/login",
          postData: {
            email_mob: email,
          },
        };
        const response = await post_Request(options);
        if (response.status === true) {
          if (validateEmail(email)) {
            setFormData((prev) => ({
              ...prev,
              email: email,
            }));
          } else {
            setFormData((prev) => ({
              ...prev,
              mobile: email,
            }));
          }
          set_loginpopup(false);
          set_otp(true);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    } else {
      // Handle case where email is not valid
    }
  };

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [error,setError]=useState('')
  
  const handleEmailChange = (e) => {
    const emailorMobValue = e.target.value;
    setEmail(emailorMobValue);
    setIsValidEmail(
      validateEmail(emailorMobValue) || isValidMobile(emailorMobValue)
    );
    setIsEmailTouched(true);
  };
  // ///////////////////////////////////////////// Registrations/////////////////////////////////////
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: validateEmail(email) ? email : "",
    mobile: isValidMobile(email) ? email : "",
    date_of_birth: "",
    pincode: "",
    country: "",
    state: "",
    city: "",
    address1: "",
    address2: "",
    gender: "",
    latitude: "56788899",
    longitude: "77665566777",
  });
  const handleRegistrationChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const validationErrors = validateRegistration({ ...formData, [name]: value });
    setError(validationErrors);
  };
  const handleRegistrationSubmit = async (e) => {
    e.preventDefault();
    try {
      // Validate the form data
      const validationErrors = validateRegistration(formData);
      setError(validationErrors)
      // Check if there are any validation errors
      if (Object.keys(validationErrors).length === 0) {
        const options = {
          url: "/users/user-register",
          postData: formData
        };
      await post_Request(options).then((response)=>{
        if(response.status===false){
       validationErrors.mobile = "Number is already registered"
        }
 
        console.log(response,"singupresponse")
        if(response.status===true){
          setSessionForLoginUser('User',response.result);
          toast.success('Registration successful.');
          handleClose()
        }
      });
      } 
    } catch (error) {
      console.error('Error submitting form data:', error.message);
      // Handle errors occurred during form submission
    }
  };
  ;
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const handleItemClick = (index) => {
    setActiveIndex(index);
  };
  const handleEmailMobileChnage=(condition)=>{
if(condition==='Login'){
  set_loginpopup(true)
  set_otp(false);
}
  }
  useEffect(() => {
    switch (location.pathname) {
      case '/Mentor_homepage':
        setActiveIndex(0);
        break;
      case '/woodburn':
        setActiveIndex(1);
        break;
      case '/Sublime':
        setActiveIndex(2);
        break;
      case '/Kid_home':
        setActiveIndex(3);
        break;
        default:
          setActiveIndex(null);
          break;
    }
  }, [location]);
  
  
  const [isActive, setIsActive] = useState(false);

  const handleSearchClick =async () => {
    setNoDataMatches(false)
    setSearchData('');
    setIsActive(!isActive);
    if (!isActive) {
      // Set focus to the input field when search is clicked
      const inputField = document.querySelector('.search-bar input');
      inputField.focus();
    }
  };
const [searchData,setSearchData]=useState('')
function handleKeyPress(event) {
  if (event.key === 'Enter') {
    setSearchData('');
      handleSerach();
  }
}

// const isActiveSearch = (searchData) => {
//   const terms = ["men", "women", "kids", "kid"];
//   const lowerCaseData = searchData.toLowerCase();
//   return terms.some(term => lowerCaseData.includes(term));
// };
const [KEY,setKey]=useState('')
const handleSerach= async()=>{
  if(!searchData){
    return
  }
  // const checkActive=isActiveSearch(searchData)
  // console.log()
  // if(checkActive){
  //   console.log(checkActive,"check")
  //   // KEY='HeadCategory'
  //   setKey('HeadCategory')
  // }else{
  //   setKey('')
  // }

  try{
    const options = {
        url: `/common/search?productName=${searchData}`,
      };
    const response = await post_RequestSearch(options)

const responseData= response.result.data;
if(responseData.length > 0){
  navigate('/Listing', { state: { responseData: responseData, "searched": true  } });
}else
{
  setNoDataMatches(true)
}
}catch(error){
console.error(error)
}
}

const [noDataMatches, setNoDataMatches] = useState(false);
const handleSearchInputFiled = (e) => {
  setNoDataMatches(false)
  setSearchData(e.target.value);
};
const handleGuestCheckout =()=>{
if(cart.length > 0){
  navigate('/add_new_address')
}else if(wishlistData.length > 0){
  navigate('/Whishlist')
}else{
  navigate('/Listing')
}
}

const handleNavigateWishlishtCart=async(key)=>{
  if(key==='Wishlist'){
if(wishlistData.length > 0){
  navigate('/Whishlist')
}else {
  setContent({ h1: "No Items In Your Wishlist", AW: "Shop Now" });
  setShowPopup(true);
}
  }else{
    if(cart.length > 0){
      handleShow()
    }else {
      setContent({ h1: "No Items In Your Cart", AW: "Shop Now" });
      setShowPopup(true);
    }
  }
}
const handleClickAnywhere = () => {
  setNoDataMatches(false); // Hide the "No Product Found" message
};
useEffect(() => {
  // Add event listener when the component mounts
  document.body.addEventListener('click', handleClickAnywhere);

  // Cleanup function to remove the event listener when the component unmounts
  return () => {
    document.body.removeEventListener('click', handleClickAnywhere);
  };
}, []);


  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    if (formData.country) {
      const country = Country.getAllCountries().find(c => c.name === formData.country);
      setSelectedCountry(country ? { value: country.isoCode, label: country.name } : null);
    }
    if (formData.state && selectedCountry) {
      const state = State.getStatesOfCountry(selectedCountry.value).find(s => s.name === formData.state);
      setSelectedState(state ? { value: state.isoCode, label: state.name } : null);
    }
    if (formData.city && selectedState) {
      const city = City.getCitiesOfState(selectedCountry.value, selectedState.value).find(c => c.name === formData.city);
      setSelectedCity(city ? { value: city.name, label: city.name } : null);
    }
  }, [formData, selectedCountry]);

  const countryOptions = Country.getAllCountries().map(country => ({
    value: country.isoCode,
    label: country.name,
  }));

  const stateOptions = selectedCountry
    ? State.getStatesOfCountry(selectedCountry.value).map(state => ({
        value: state.isoCode,
        label: state.name,
      }))
    : [];

  const cityOptions = selectedState
    ? City.getCitiesOfState(selectedCountry.value, selectedState.value).map(city => ({
        value: city.name,
        label: city.name,
      }))
    : [];

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setSelectedState(null);
    setSelectedCity(null);
    setFormData({ ...formData, country: country ? country.label : '', state: '', city: '' });
  };

  const handleStateChange = (state) => {
    setSelectedState(state);
    setSelectedCity(null);
    setFormData({ ...formData, state: state ? state.label : '', city: '' });
  };

  const handleCityChange = (city) => {
    setSelectedCity(city);
    setFormData({ ...formData, city: city ? city.label : '' });
  };
  
const googleAuthKey=async(email)=>{
  try {
    const options = {
      url: "/users/get-google-auth-key",
      postData: {
        email: email,
      },
    };
    const response =await post_Request(options)
      return response;
 
  } catch (error) {
    console.error(error);
  }
}
const loginWithGoogle=async(auth)=>{
  try {
    const response = await axios.post(
      'https://api.sportkingfashion.com/front/v1/users/google-login',
      {},
      {
        headers: {
          'authToken': auth
          }
      }
    );
    console.log(response,"respose&&&&&88890000")
    const userData=response.data.response.result
if(userData){
  set_loginpopup(false)
  setSessionForLoginUser('User',userData)
  sessionStorage.setItem('TOKEN',userData.token)
}
    // setResponseData(response.data);
  } catch (error) {
    setError(error);
  }
}
    const handleGoogleLogin = async() => {
      const firebaseConfig = {
        apiKey: "AIzaSyCoqApUzzxmpbKGhBZx1uGGWrqAoK56wb8",
        authDomain: "sportking.firebaseapp.com",
        projectId: "sportking",
        storageBucket: "sportking.appspot.com",
        messagingSenderId: "114977473371",
        appId: "1:114977473371:web:a8ecd4e999e44f99b3107e",
        measurementId: "G-L2SJPLN0QZ"
      };
      const app = initializeApp(firebaseConfig);
      const auth = getAuth(app);
      const googleProvider =await new GoogleAuthProvider();
   const response =  await signInWithPopup(auth, googleProvider);
const UserCrendential=response?.user?.email;
if(UserCrendential){
 const auth = await googleAuthKey(UserCrendential)
 if(auth){
loginWithGoogle(auth.result)
 }
}

    };
    
    useEffect(()=>{
      if(!(cart?.length > 0)){
        handleClose()
      }
    },[cart])


  return (
    <>

      <div className={`header ${isSticky ? "sticky" : "sticky"}`}>
        <div className="sticky_es">  
        
          {/* <hr></hr> */}

          <div className="cart_header">

          <div>
     
    </div>
          <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
            <div className="row">
            <ul className="header_main">
      <li onClick={() => handleItemClick(0)} >
        <Link to="/Mentor_homepage" className={activeIndex === 0 ? 'active' : ''} ><img src={process.env.PUBLIC_URL + "../img/mentorLgoNew.png"} style={{padding:"3%"}} /></Link>
      </li>
      <li onClick={() => handleItemClick(1)}>
        <Link to="/woodburn" className={activeIndex === 1 ? 'active' : ''} ><img src={process.env.PUBLIC_URL + "../img/wdNew.png"} id="woodburn"/></Link>
      </li>
      <li onClick={() => handleItemClick(2)}>
        <Link to="/Sublime" className={activeIndex === 2 ? 'active' : ''} ><img src={process.env.PUBLIC_URL + "../img/media_sublime.png"} /></Link>
      </li>
      <li onClick={() => handleItemClick(3)}>
        <Link to="/Kid_home" className={activeIndex === 3 ? 'active' : ''} ><img src={process.env.PUBLIC_URL + "../img/sprtkinglatestlogo.png"} /></Link>
      </li>
    </ul>
            </div>
            </div>
            <div className="container pt-4 pb-4">
            
              <div className="row mobile_viewes">
               
                <div className="col-md-4 col-6">
                <div className="mobile_view_search">
                    <div className={`search-bar ${isActive ? 'active' : ''}`}>
        {/* <input type="text"    placeholder="Search for Products, Category"  onChange={handleSearchInputFiled} 
        value={searchData}/> */}

<input 
    type="text" 
    placeholder="Search for products, category"      
    onChange={handleSearchInputFiled} 
    onKeyDown={handleKeyPress}
    value={searchData} 
/>

    {noDataMatches && (
        <div className="no-matches">No Product Found.</div>
    )}
      </div>
      <div className={`search ${isActive ? 'active' : ''}`} onClick={handleSearchClick}>
        <div className="search-icon"></div>

      </div>
                      </div>
                <div className="menu-toggle" onClick={toggleMenu}>
        {menuOpen ? <RxCross2 /> : <FaBars />} {/* Conditional rendering of menu and close icons */}
      </div>
                <ul className={`header-lk ${menuOpen ? 'menu-open' : ''}`}>

 
                {Object.keys(categories)?.map((item, index) => (
  <li id="drop_down" key={index}>
    <Link className="category">
      {item}
    </Link>
    <div className="full_width_drop_down">
      <div className="" id="drop_down_header">
        {Array.from({ length: 3 }).map((_, colIndex) => (
          <div className="col-md-2 col-12" key={colIndex}>
            <ul>
              {categories[item]
                .slice(
                  // Instead of filling up columns equally, adjust the logic to fill left to right
                  (colIndex * Math.ceil(categories[item].length / 3)), 
                  ((colIndex + 1) * Math.ceil(categories[item].length / 3))
                )
                .map((subcategory) => (
                  <li key={subcategory.name}>
                    <span
                      className="subCat_hed"
                      onClick={() => {
                        const KEY = "HeadCategory";
                        navigate('/Listing', { state: { ...subcategory, KEY } });
                      }}
                    >
                      {subcategory.name}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        ))}
        {product?.length > 0 &&
          product.map((item, index) => (
            <div className="product_detail" key={index}>
              <div>
                <img
                  src={
                    IMAGE_BASE_URL +
                    (colorIndex?.productId === item._id
                      ? item.color_size_details[colorIndex.index].images[0]
                      : item.color_size_details[0].images[0])
                  }
                  alt="logo"
                  className="logo"
                />
              </div>
              <div className="">
                <div className="color_box">
                  <ul>
                    {item.color_size_details.map((color, colorIndex) => (
                      <div
                        key={colorIndex}
                        className="color-square cursor-pointer"
                        style={{
                          backgroundColor: color.color_code,
                          border:
                            selectedColor !== null &&
                            color &&
                            color?._id === selectedColor.colorId &&
                            item._id === selectedColor.productId
                              ? "1px solid black"
                              : colorIndex === 0 &&
                                item._id !== selectedColor?.productId
                              ? "1px solid black"
                              : "none",
                        }}
                        onClick={() =>
                          handleColorSelect(item._id, color._id, color, colorIndex)
                        }
                      ></div>
                    ))}
                  </ul>
                  <p>
                    (
                    {item.color_size_details
                      .map((color) => {
                        const capitalizedColorName = color.color_name
                          .split(' ')
                          .map(
                            (word) => word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(' ');

                        return capitalizedColorName;
                      })
                      .join(', ')}

                    )
                  </p>
                </div>
                <p>{item.product_title}</p>
                <p>{item.product_name}</p>
                <p>
                  ₹{" "}
                  {colorIndex.productId === item._id
                    ? item.color_size_details[colorIndex.index].selling_price
                    : item.color_size_details[0].selling_price}
                </p>
                <div className="d-flex social_icon header_icons">
                  {isProductInWishlist(item._id) ? (
                    <div className="select_product heart">
                      <FontAwesomeIcon
                        icon={faHeartRegular} // Use faHeartRegular for unfilled heart
                        style={{
                          color: "#df1b22",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const data =
                            productDetailsSize ?? item.color_size_details[0];
                          const status = isProductInWishlist(item._id);
                          handleHeartClick(item, data, status);
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"}
                      alt="logo"
                      onClick={() => {
                        const data =
                          productDetailsSize ?? item.color_size_details[0];
                        const status = isProductInWishlist(item._id);
                        handleHeartClick(item, data, status);
                      }}
                    />
                  )}
                  <img
                    src={process.env.PUBLIC_URL + "../img/solar_bag-3-linear.svg"}
                    alt="logo"
                    id="sign_in"
                    onClick={() => {
                      const data = item.color_size_details[0];
                      handleCart(item, data);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  </li>
))}


                    {/* <li className="header_active">
                      {" "}
                      <Link to="/Listing">Women </Link>
                    </li> */}
                   
                  </ul>
                </div>
                <div className="col-md-4 col-6">
                  <div className="header_logo">
                  <Link to="/">
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "../img/sportking-dark.svg"}
                      alt="logo"
                      className="logo"
                    />{" "}
                  </Link>
                  </div>
               
                </div>
                <div className="col-md-4 col-12">
                  <ul className="cart_linking">
                  <li className="web_view_search">
                    <div >
                    <div className={`search-bar ${isActive ? 'active' : ''}`}>
                    <div className="search-container">
  <input 
    type="text" 
    placeholder="Search for products, category"         
    onChange={handleSearchInputFiled} 
    onKeyDown={handleKeyPress}
    value={searchData} 
/>

    {noDataMatches && (
        <div className="no-matches">No Product Found.</div>
    )}
</div>
      </div>
      <div className={`search ${isActive ? 'active' : ''}`} onClick={handleSearchClick}>
        <div className="search-icon"></div>
      </div>
                      </div>
                
                    {/* <IoSearchOutline />
                 */}
                    </li>
                    <li onClick={thank_event}>
                    <IoGiftOutline />
                    </li>
                    <li  onClick={()=>{handleNavigateWishlishtCart("Wishlist")}}>
                    <CiHeart className="heart_icon profile_header" />
                    {wishlistData.length > 0?<b className="profile_header whistlist_no">{wishlistData.length}</b>:""}
                    </li>
                    <li onClick={()=>{handleNavigateWishlishtCart("cart")}}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "../img/solar_bag.svg"
                        }
                        id="sign_in"
                        alt="logo"
                      /> {cart.length >0 ? <b className="profile_header whistlist_no" > {cart.length} </b> :""}
                    </li>
                    {/* <li onClick={sign_up}>
                      <img
                        src={process.env.PUBLIC_URL + "../img/solar_user-outline.svg"}
                        alt="logo"
                      />
                    </li> */}
                                   <li id="sign_in">
     {User ?  <p className="profile_header" onClick={()=>{navigate('/Maindashbaord')}}> {User.lastName ? User.firstName:  "Guest User" } </p> 
     : <p className="profile_header" onClick={sign_up} ><li onClick={sign_up}>
                      <img
                        src={process.env.PUBLIC_URL + "../img/solar_user-outline.svg"}
                        alt="logo"
                      />
                    </li></p>}
    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     
      <Modal show={show} onHide={handleClose} id="cart_model">
        <div className="cross">
          <p className="heading mb-0">Your Product Summary</p>
          <FontAwesomeIcon icon={faTimes} onClick={handleClose} id="icon" />
        </div>
        <hr></hr>

        <Modal.Body>
          {/* {User? "":
          <div className="login">
            <p>
              Log in or create an account to enjoy a personalized shopping
              experience and speed up future purchases.
            </p>
            <button onClick={sign_up}>Login or Register </button>
          </div>
} */}
          <div className="order_item mt-0">
            {/* <p className="heading">Items In Your Order</p>
            <hr className="heading_line"></hr> */}
{cart?.map((item,index)=>(
  <div className="cart_product_deatil" key={index}>
  <div className="cart_product_img">
    <img
      src={IMAGE_BASE_URL+item.product_image}
      alt="logo"
    />
  </div>
  <div className="total-product">
    <h1>{item.product_name}</h1>
    <p className="price mb-0">₹{item.price}</p>
    <p className="product_color mb-0">Color : {item.color}</p>
    <p className="product_color mb-0 ">Size : {item.size}</p>
    <div className="quantity">
      <p className="product_color">Quantity : </p>
      <div className="quantity-btn">
        <button onClick={()=>{if(item.qty===0) return ; handleDecrement(item,-1)}}  className="symbol">
          -
        </button>
        <button id="quantity">{item.qty}</button>
        <button onClick={()=>{handleIncrement(item,1)}} className="symbol">
          +
        </button>
      </div>
    </div>
  </div>
</div>
))}
          
           
         
          </div> 
          <div className="subtotal_product mt-3">
            <p className="heading mb-0">Subtotal</p>
            <p className="heading mb-0">₹{subtotal}</p>
          </div>
        </Modal.Body>
        <hr></hr>
        <div className="model_footer">
          <button id="proceed_checkout" onClick={()=>{navigate('/add_new_address')}}>Proceed To Checkout</button>
          <button className="view_cart" id="cart_icon">
            <Link to="/Cart">View Your Cart</Link>
          </button>
        </div>
      </Modal>

      <Modal show={thnaks_confirm} onHide={handleClose} id="thanks_popup">
        <div className="cross">
          <FontAwesomeIcon icon={faTimes} onClick={handleClose} id="icon" />
        </div>
        <hr></hr>

        <Modal.Body>
          <div className="img-bg">
            <div className="limit_offer">
              <h1>Limited Offer</h1>
              <h2>
                Here's <span id="percent_offer">15% off</span> on <br />
                your First purchase
              </h2>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem
              </p>
            </div>
            <div className="offer_img">
              <div className="inner_product_popup">
                <h2 className="discount_amount">15%</h2>
                <p>
                  Discount <br /> Voucher
                </p>
              </div>
              <div className="thanks_line">
                <h1>Thank you</h1>
                <p className="discount_order">
                  Thank you for ordering from <br /> sportking Use code ”FIRSTUSER15”{" "}
                  <br></br> at checkout for 15% discount.
                </p>
              </div>
            </div>

            <hr></hr>
            <div className="model_footer">
              <button className="view_cart" onClick={()=>{navigate('/Listing')}}> <Link to='/Listing' style={{textDecoration:'none',color:"inherit"}}>Shop Now </Link></button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal show={loginpopup} onHide={handleClose} id="login_popup">
        <div className="cross">
          <FontAwesomeIcon icon={faTimes} onClick={handleClose} id="icon" />
        </div>

        <Modal.Body>
          <div className="model_inner_content">
            <img
              src={`${images_url}sportking-dark+(1).svg`}
              alt="logo"
            />
            {/* <p>
              Discover the latest launches <br></br>and be the first to get
              <br></br> notifications for new drops.
            </p> */}
            <h2 className="mt-4">Let’s Login / Create Your Account</h2>
            <input
              type="text"
              placeholder="Enter Mobile No ./Email Address"
              value={email}
              onChange={handleEmailChange}
              className={!isValidEmail && isEmailTouched ? "invalid" : ""}
            />{" "}
            <br></br>
            {!isValidEmail && isEmailTouched && (
              <p className="error-message text-left">
                Please enter a valid email/mobile no.
              </p>
            )}
            <button className="otp_btn" onClick={handleLogin}>
              Send OTP
            </button>
            <br></br>
            <img src={`${images_url}or.svg`} alt="logo" />
            <div className="mt-4">
              <button className="google_btn" onClick={handleGoogleLogin}>
                <img
                  src={`${images_url}google_icon.svg`}
                  alt="logo"
                  
                />{" "}
                Use Google Account
              </button>
              <br></br>
              <button className="checkout" onClick={handleGuestCheckout}>Checkout As A Guest</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={otp} onHide={handleClose} id="login_popup">
        <div className="cross">
          <FontAwesomeIcon icon={faTimes} onClick={handleClose} id="icon" />
        </div>

        <Modal.Body>
          <div className="model_inner_content">
            <img
                src={`${images_url}sportking-dark+(1).svg`}
              alt="logo"
            />
            <h2>OTP Verification</h2>
            <p>
              Enter OTP (One Time Passcode) Sent <br></br> To {email}{" "}
              <Link to="#" className="change" onClick={()=>{handleEmailMobileChnage('Login')}}>
                {" "}
                Change
              </Link>{" "}
            </p>
            <ul id="otp_inputs">
            {otpValue.map((value, index) => (
<li key={index}>
  <input
    ref={(ref) => (inputRefs.current[index] = ref)}
    type="tel" // Use type="tel" to allow only numeric input
    pattern="[0-9]*" // Set pattern to allow only numeric characters
    inputMode="numeric" // Set inputMode to ensure numeric keyboard on mobile devices
    placeholder="X"
    value={value}
    onChange={(e) => handleChange(index, e.target.value)}
  />
</li>

))}

                         
            </ul>
            {wrongOtp ? <p className='error_Validtaion'>{wrongOtp}</p> : null}
            <button className="otp_btn" onClick={handleLoginOtp}>
              Verify & Proceed
            </button>
            <br></br>
            <p>
              Didn't Receive OTP Code? <br></br>
              <Link to="#" className="change">
                {" "}
                RESEND CODE
              </Link>{" "}
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={address} onHide={handleClose} size="lg" id="add_address">
        <div className="cross py-0">
          <img
            src={`${images_url}ball_icon.svg`}
            alt="logo"
            className="model_logo"
          />
          <div className="update_profile_header">
            <h1 className="heading">Complete Your Profile Details</h1>
            {/* <p>
              {" "}
              Enter OTP (One Time Passcode) Sent To {email}{" "}
              <Link to="#" className="change">
                Change
              </Link>
            </p> */}
                   <p>Enter your personal details</p>
          </div>
          <img
            src={`${images_url}cross.svg`}
            alt="logo"
            onClick={handleClose}
            id="icon"
            className="model_logo"
          />
        </div>
        <div></div>

        <Modal.Body className="py-0">
          <form>
            <div className="row mt-2">
              <h1 className="sub_heading"> Fill the details Below</h1>
            </div>
            <div className="row">
              <div className="col-md-4 padding-right">
                <input
                  type="text"
                  name="firstName"
                  value={formData?.firstName}
                  placeholder="First Name"
                  className="form_group"
                  onChange={handleRegistrationChange}
                />
                       {error.firstName && <div className="error_Validtaion">{error.firstName}</div>}
              </div>
              <div className="col-md-4 padding-right">
                <input
                  type="text"
                  name="lastName"
                  value={formData?.lastName}
                  placeholder="Last Name"
                  className="form_group"
                  onChange={handleRegistrationChange}
                />
           {error.lastName && <div className="error_Validtaion">{error.lastName}</div>}
              </div>
              <div className="col-md-4 padding-right">
                <input
                  type="number"
                  name="mobile"
                  placeholder="Mobile Number"
                  className="form_group"
                  value={formData?.mobile}
                  disabled={isValidMobile(email) ? true : false}
                  onChange={handleRegistrationChange}
                />
     {error.mobile && <div className="error_Validtaion">{error.mobile}</div>}
              </div>
              <div className="col-md-12 padding-right">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  className="form_group"
                  value={formData?.email}
                  disabled={validateEmail(email)? true : false}
                  onChange={handleRegistrationChange}
                />
                           {error.email && <div className="error_Validtaion">{error.email}</div>}
              </div>
              <div className="col-md-4 padding-right">
                <input
                  type="date"
                  name="date_of_birth"
                  placeholder="DOB(dd-mm-yyyy)"
                  className="form_group"
                  onChange={handleRegistrationChange}
                />
                           {error.date_of_birth && <div className="error_Validtaion">{error.date_of_birth}</div>}
              </div>
              <div className="col-md-4 padding-right">
                <select
                  className="form_group"
                  name="gender"
                  value={formData.gender}
                  onChange={handleRegistrationChange}
                >
                  <option>Gender</option>
                  <option>Women</option>
                  <option>Men</option>
                </select>
                {error.gender && <div className="error_Validtaion">{error.gender}</div>}
              </div>
              <div className="col-md-4">
                <input
                  type="Pincode"
                  name="pincode"
                  className="form_group"
                  placeholder="Pincode"
                  onChange={handleRegistrationChange}
                />
                           {error.pincode && <div className="error_Validtaion">{error.pincode}</div>}
              </div>

              <div className="col-md-12">
                <input
                  type="text"
                  name="address1"
                  className="form_group"
                  placeholder="Address Line 1"
                  onChange={handleRegistrationChange}
                />
                           {error.address1 && <div className="error_Validtaion">{error.address1}</div>}
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  name="address2"
                  className="form_group"
                  placeholder="Address Line 2"
                  onChange={handleRegistrationChange}
                />
                           {error.address2 && <div className="error_Validtaion">{error.address2}</div>}
              </div>

              <div className="col-md-4 padding-right mt-3">
        <Select
          options={countryOptions}
          value={selectedCountry}
          onChange={handleCountryChange}
          placeholder="Select Country"
          // className="form_group"
        />
        {error.country && <div className="error_Validtaion">{error.country}</div>}
      </div>
      <div className="col-md-4 padding-right mt-3">
        <Select
          options={stateOptions}
          value={selectedState}
          onChange={handleStateChange}
          placeholder="Select State"
          // className="form_group"
          isDisabled={!selectedCountry}
        />
        {error.state && <div className="error_Validtaion">{error.state}</div>}
      </div>
      <div className="col-md-4 padding-right mt-3">
        <Select
          options={cityOptions}
          value={selectedCity}
          onChange={handleCityChange}
          placeholder="Select City"
          // className="form_group"
          isDisabled={!selectedState}
        />
        {error.city && <div className="error_Validtaion">{error.city}</div>}
      </div>
            </div>
          </form>
        </Modal.Body>

        <div className="model_footer">
          <button className="view_cart" onClick={handleRegistrationSubmit}>
            {/* <Link to="/Maindashbaord" onClick={handleRegistrationSubmit}> */}
              Save & Proceed
            {/* </Link> */}
          </button>
          {/* <button className='skip_btn'>Skip For Now</button> */}
        </div>
      </Modal>



      
      <Modal show={showSizepopup} onHide={handleClose}  id="feedback_popup" centered>
          <div className='cross py-0'>
          <img   src={`${images_url}ball_icon.svg`} alt="logo" className="model_logo" />
         
          <img   src={`${images_url}cross.svg`} alt="logo"  onClick={handleClose} id="icon" className="model_logo" />
          
          </div>
        <div>
        <h1>Please Select a Size</h1>
        <div className="product-size">
                  <ul>
                    {productSieColor &&
                      productSieColor?.size[0].split(",").map((size, index) => (
                        <li
                          key={index}
                          className="cursor-pointer "
                          style={{
                            // backgroundColor: 'red',
                            border:
                              selectedSize === size ? "2px solid black" : "",
                          }}
                          onClick={() => setSelectedSize(size)}
                        >
                          {size}
                        </li>
     

                      ))}
                       
                  </ul>
                  {error ? <p className="error_Validtaion" style={{color:"red"}}>{error}</p> : ""}
                </div>


       
        </div>
      {content.p===!null?  
        <Modal.Body className="py-0">
        <div className="thank_you_popup">
            <div className="row">
         <div className="col-md-4">
          <h2>10%</h2>
          <p className="discount_voucher">Discount Voucher</p>
         </div>
         <div className="col-md-8 px-0">
            <h2  className="thank_you_heading">  Thank you</h2>
            <p className="inner_content"> for ordering from sportking Use code  <span>”THANKS”</span>   at checkout for 10% discount.</p>
       

         </div>
        </div>
        </div>
        </Modal.Body>
     :""}
         <div className='model_footer'>
          <button className='view_cart' onClick={handleNavigate} >{content?.h3?"View my orders":"Proceed To Next"}</button>
          </div> 
      </Modal>
      {/* <ThankYoupopup isOpen={showPopup} content={content}/> */}
      <ToastContainer />
    </>
  );
});
export default Header;
