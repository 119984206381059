import React, { createContext, useEffect, useState } from "react";
import { post_Request } from "../Controller/Home_controller";
import { addToCart } from "../Controller/commonController";
import { fetchSystemAddress } from "../Controller/API";
import { getSessionForLoginUser } from "../utils/helper";
import Woodburn from "../woodenbern/woodburn";
import axios from "axios";
import { API_BASE_URL, API_KEY } from "../Config/constant";

export const Store = createContext();

export const CartProvider = ({ children }) => {
  const User = getSessionForLoginUser("User");
  const [cart, setCart] = useState([]);
  const [addressList, setAddressList] = useState(null);
  const [bannerProductData, setBannerProductData] = useState([]);

  const [brandBannerProductData, setBrandBannerProductData] = useState([]);

  const [woodBurnBannerProductData, setWoodBurnBannerProductData] = useState(
    []
  );
  const [sublimeBannerProductData, setSublimeBannerProductData] = useState([]);
  const [kidsBannerProductData, setKidsBannerProductData] = useState([]);

  const [homeBanner, setHomeBanner] = useState([]);
  const [wishlistData, setWishListData] = useState("");
  const [categories, setCategories] = useState("");
  const[Collection,setCollection]=useState('')
  const [product, setProduct] = useState(null);
  const [trickers,setTrickers] =useState('')
  const subtotal = cart.reduce(
    (total, item) => total + item.qty * item.unitPrice,
    0
  );

  const getCart = async () => {
    try {
      const options = {
        url: "/order/getCart",
        postData: {
          current_ip: await fetchSystemAddress(),
        },
      };
      await post_Request(options).then((response) => {
        setCart(response.result.result);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateCartItemQuantity = async (item, incDec) => {
    try {
      const productDetails = {
        product_id: item.product_id,
        product_price: item.unitPrice, // Assuming you'll assign a price here
        product_name: item.product_name,
        qty: item.qty,
        cartId: item._id,
      };
      const selectedSize = item.size;
      const productSieColor = item.color;
      const result = await addToCart(
        productDetails,
        selectedSize,
        productSieColor,
        incDec
      );

      if (result && result._id) {
        setCart((prevCart) => {
          const updatedCartList = prevCart.map((cartItem) => {
            if (cartItem._id === result._id) {
              return { ...cartItem, qty: result.qty, price: result.price };
            }
            return cartItem;
          });
          return updatedCartList;
        });
      } else {
        console.log(
          "Failed to update cart item quantity. Removing the item from the cart."
        );
        setCart((prevCart) =>
          prevCart.filter((cartItem) => cartItem._id !== item._id)
        );
        // Optionally, show an alert or handle the failure in another way
      }
    } catch (error) {
      console.error("Error updating cart item quantity:", error);
      // Handle error, show error message to the user, etc.
    }
  };

  const getaddressList = async () => {
    try {
      const options = {
        url: "/users/address-list",
      };
      await post_Request(options).then((response) => {
        setAddressList(response.result.result);
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getHomeProductBanner = async () => {
    try {
      const options = {
        url: "/common/homepage-product-banners",
        postData: {
          page_name: "home",
          conditions: {
            type: [
              "hot_list",
              "latest_arrival",
              "featured_product",
              "new_brand_collection",
              "bestsellers",
              "Winter Collection",
            ],
          },
        },
      };

      const response = await post_Request(options);
      const data = response.result.result;

      const bannerProductData = {
        hotListData: [],
        latestArrivalData: [],
        featuredProduct: [],
        newBrandCollection: [],
        bestSellers: [],
        winterCollection: [],
      };

      data.forEach((item) => {
        switch (item.type) {
          case "hot_list":
            bannerProductData.hotListData.push(item);
            break;
          case "latest_arrival":
            bannerProductData.latestArrivalData.push(item);
            break;
          case "featured_product":
            bannerProductData.featuredProduct.push(item);
            break;
          case "new_brand_collection":
            bannerProductData.newBrandCollection.push(item);
            break;
          case "bestsellers":
            bannerProductData.bestSellers.push(item);
            break;
          case "Winter Collection":
            bannerProductData.winterCollection.push(item);
          default:
            break;
        }
      });

      setBannerProductData(bannerProductData);
    } catch (error) {
      console.error(error);
    }
  };
  const getMentorHomeProductBanner = async () => {
    try {
      const options = {
        url: "/common/brand-homepage-product-banners",
        postData: {
          brand_name: "mentor",
          conditions: {
            type: [
              "latest_arrival",
              "featured_product",
              "Winter Collection",
              "bestsellers",
            ],
          },
        },
      };

      const response = await post_Request(options);
      const data = response.result.result;
      console.log(response.result,"cheghrespo____")
      const bannerProductData = {
        latestArrivalData: [],
        featuredProduct: [],
        newBrandCollection: [],
        bestSellers: [],
        winterCollection: [],
      };

      data.forEach((item) => {
        switch (item.type) {
          case "latest_arrival":
            bannerProductData.latestArrivalData.push(item);
            break;
          case "featured_product":
            bannerProductData.featuredProduct.push(item);
            break;
          case "bestsellers":
            bannerProductData.bestSellers.push(item);
            break;
          case "Winter Collection":
            bannerProductData.winterCollection.push(item);
          default:
            break;
        }
      });

      setBrandBannerProductData(bannerProductData);
    } catch (error) {
      console.error(error);
    }
  };
  const getWoodburnHomeProductBanner = async () => {
    try {
      const options = {
        url: "/common/brand-homepage-product-banners",
        postData: {
          brand_name: "woodburn",
          conditions: {
            type: ["latest_arrival", "featured_product", "bestsellers"],
          },
        },
      };

      const response = await post_Request(options);
      const data = response.result.result;

      const bannerProductData = {
        latestArrivalData: [],
        featuredProduct: [],
        bestSellers: [],
      };

      data.forEach((item) => {
        switch (item.type) {
          case "latest_arrival":
            bannerProductData.latestArrivalData.push(item);
            break;
          case "featured_product":
            bannerProductData.featuredProduct.push(item);
            break;
          case "new_brand_collection":
            bannerProductData.newBrandCollection.push(item);
            break;
          case "bestsellers":
            bannerProductData.bestSellers.push(item);
            break;
          default:
            break;
        }
      });
      setWoodBurnBannerProductData(bannerProductData);
    } catch (error) {
      console.error(error);
    }
  };

  const getSublimeHomeProductBanner = async () => {
    try {
      const options = {
        url: "/common/brand-homepage-product-banners",
        postData: {
          brand_name: "sublime",
          conditions: {
            type: ["latest_arrival", "featured_product", "bestsellers"],
          },
        },
      };

      const response = await post_Request(options);
      const data = response.result.result;

      const bannerProductData = {
        latestArrivalData: [],
        featuredProduct: [],
        bestSellers: [],
      };

      data.forEach((item) => {
        switch (item.type) {
          case "latest_arrival":
            bannerProductData.latestArrivalData.push(item);
            break;
          case "featured_product":
            bannerProductData.featuredProduct.push(item);
            break;
          case "new_brand_collection":
            bannerProductData.newBrandCollection.push(item);
            break;
          case "bestsellers":
            bannerProductData.bestSellers.push(item);
            break;
          default:
            break;
        }
      });
      setSublimeBannerProductData(bannerProductData);
    } catch (error) {
      console.error(error);
    }
  };

  const getKidsHomeProductBanner = async () => {
    try {
      const options = {
        url: "/common/brand-homepage-product-banners",
        postData: {
          brand_name: "kid",
          conditions: {
            type: ["latest_arrival", "featured_product", "bestsellers"],
          },
        },
      };

      const response = await post_Request(options);
      const data = response.result.result;
      const bannerProductData = {
        latestArrivalData: [],
        featuredProduct: [],
        bestSellers: [],
      };

      data.forEach((item) => {
        switch (item.type) {
          case "latest_arrival":
            bannerProductData.latestArrivalData.push(item);
            break;
          case "featured_product":
            bannerProductData.featuredProduct.push(item);
            break;
          case "new_brand_collection":
            bannerProductData.newBrandCollection.push(item);
            break;
          case "bestsellers":
            bannerProductData.bestSellers.push(item);
            break;
          default:
            break;
        }
      });
      setKidsBannerProductData(bannerProductData);
    } catch (error) {
      console.error(error);
    }
  };
  const getBanner = async () => {
    try {
      const options = {
        url: "/common/homepage-banner",
        postData: { page_name: ["home", "list",'brands'] ,brand_name :['mentor','woodburn','sublime','kid'] },
      };

      const response = await post_Request(options);
      const data = response.result.result;

      const homeBannerData = {
        homeBannerData: [],
        listBannerData: [],
        mentorBannerData:[],
        WoodburnBannerData:[],
        sublimeBannerData:[],
        kidBannerData:[]
      };
      data.forEach((item) => {
        switch (item.page_name) {
          case "home":
            homeBannerData.homeBannerData.push(item);
            break;
          case "list":
            homeBannerData.listBannerData.push(item);
            break;
            case "brands":
              if(item.brand_name==='mentor'){
                homeBannerData.mentorBannerData.push(item)
              }
                else if(item.brand_name==='woodburn'){
                homeBannerData.WoodburnBannerData.push(item)
              }
             else  if(item.brand_name==='sublime'){
                homeBannerData.sublimeBannerData.push(item)
              }
            else  if(item.brand_name==='kid'){
                homeBannerData.kidBannerData.push(item)
              }
          
break;
          default:
            break;
        }
      });
      setHomeBanner(homeBannerData);
    } catch (error) {
      console.error(error);
    }
  };

  const removeFromCart = (itemId) => {
    setCart(cart.filter((item) => item.id !== itemId));
  };

  const wishlist = async () => {
    try {
      const options = {
        url: "/order/wishlist",
        postData: {
          // ...(user_id && ? { user_id, current_ip } : user_id ? { user_id } : { current_ip }),
          user_id: User?._id,
          current_ip: await fetchSystemAddress(),
        },
      };
      await post_Request(options).then((response) => {
        setWishListData(response.result.result);
      });
    } catch (error) {
      console.error(error);
    }
  };
  // const getSubCategories = async (categories) => {
  //   let categorySubCategoryData = {};
  
  //   try {
  //     const categoryIds = categories.map((item) => item._id);
  //     const options = {
  //       url: "/common/get-subcategories",
  //       postData: {
  //         category_id: categoryIds,
  //       },
  //     };
  //     const response = await post_Request(options);
  //     const data = response.result.result;
  //     // Fetch product data for each subcategory
  //     for (const subcategory of data) {
  //       const productOptions = {
  //         url: "/common/get-products",
  //         postData: {
  //         condition: { 
  //           status : "A",
  //           category_id: subcategory.category_id
  //         },
  //         limit     : 1,
  //         page     : 1 
       
  //         },
  //       };
  //       const productResponse = await post_Request(productOptions);
  //       const products = productResponse.result.productData;
  //       if (products.length > 0) {
  //         const matchingCategory = categories.find(
  //           (category) => category._id === subcategory.category_id
  //         );
  
  //         if (matchingCategory) {
  //           setProduct(products);
  //           if (!categorySubCategoryData[matchingCategory.name]) {
  //             categorySubCategoryData[matchingCategory.name] = [];
  //           }
  //           categorySubCategoryData[matchingCategory.name].push(subcategory);
  //         }
  //       }
  //     }
  
  //     // Update state with filtered subcategory data
  //     setCategories(categorySubCategoryData);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // it is for show only header category in which there is some atleast one subcategory
  // const getSubCategories = async (categories) => {
  //   let categorySubCategoryData = {};

  //   try {
  //     const categoryIds = categories.map((item) => item._id);
  //     const options = {
  //       url: "/common/get-subcategories",
  //       postData: {
  //         category_id: categoryIds,
  //       },
  //     };
  //     const response = await post_Request(options);
  //     const data = response.result.result;
  //     data.forEach((item) => {
  //       const matchingCategory = categories.find(
  //         (category) => category._id === item.category_id
  //       );
  //       if (matchingCategory) {
  //         if (!categorySubCategoryData[matchingCategory.name]) {
  //           categorySubCategoryData[matchingCategory.name] = [];
  //         }
  //         categorySubCategoryData[matchingCategory.name].push(item);
  //       }
  //     });

  //     setCategories(categorySubCategoryData);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getSubCategories = async (categories) => {
    let categorySubCategoryData = {};
  
    try {
      // Initialize each category with an empty array
      categories.forEach(category => {
        categorySubCategoryData[category.name] = [];
      });
  
      const categoryIds = categories.map((item) => item._id);
      const options = {
        url: "/common/get-subcategories",
        postData: {
          category_id: categoryIds,
        },
      };
  
      const response = await post_Request(options);
      const data = response.result.result;
  
      data.forEach((item) => {
        const matchingCategory = categories.find(
          (category) => category._id === item.category_id
        );
        if (matchingCategory) {
          categorySubCategoryData[matchingCategory.name].push(item);
        }
      });
  
      setCategories(categorySubCategoryData);
    } catch (error) {
      console.error(error);
    }
  };
  
  const getCategories = async () => {
    try {
      const options = {
        url: "/common/get-categories",
        postData: {
          header_show: "Yes",
        },
      };
      await post_Request(options).then((response) => {
        const data = response.result.result;
        getSubCategories(data.sub_caterogies);
      });
    } catch (error) {
      console.error(error);
    }
  };
  

  const getCollection = async () => {
    try {
      const options = {
        url: "/common/get-Collections",
        // postData: {
        //   header_show: "Yes",
        // },
      };
      await post_Request(options).then((response) => {
        const data = response.result.result;
        setCollection(data.sub_caterogies[0]);
      });
    } catch (error) {
      console.error(error);
    }
  };
  
  const getTrickers=async()=>{
try{
const response=await axios.post(API_BASE_URL+'/common/trickers',{},{
  headers:{
    key:API_KEY
  }

})

setTrickers(response.data)
}catch(error){
  console.error(error)
}
  }

  useEffect(() => {
    getCollection()
    getCart();
    getBanner();
    getHomeProductBanner();
    wishlist();
    getCategories();
    getMentorHomeProductBanner();
    getWoodburnHomeProductBanner();
    getSublimeHomeProductBanner();
    getKidsHomeProductBanner();
    getTrickers()
    if(User){
      getaddressList();
    }
  }, []);

  return (
    <Store.Provider
      value={{
        cart,
        bannerProductData,
        homeBanner,
        getCart,
        Collection,
        addToCart,
        removeFromCart,
        updateCartItemQuantity,
        subtotal,
        addressList,
        wishlistData,
        categories,
        brandBannerProductData,
        woodBurnBannerProductData,
        sublimeBannerProductData,
        kidsBannerProductData,
        wishlist,
        product,
        getaddressList,
        trickers
      }}
    >
      {children}
    </Store.Provider>
  );
};
